$primary: #00d1b2;
$primary-invert: #ffffff;
$light-grey: #fafaf7;

html,
body,
#layout-wrapper {
  height: 100%; }

#layout-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 5rem);
  background-color: #fbfbff; }

.is-fullheight {
  min-height: calc(100vh - 5rem) !important; }

#content-wrapper {
  flex: 1; }

//$body-background-color: $light-grey
$modal-background-background-color: $light-grey;
$modal-card-head-background-color: $light-grey;
$modal-card-body-background-color: $light-grey;
$modal-card-head-border-bottom: 'none';
$modal-card-foot-border-top: 'none';

.modal {
  z-index: 100 !important; }

nav {
  border-bottom: lightgray solid 0.1vmin; }

.content .taglist {
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  li {
    padding: 0 2rem 1rem 0;
    margin-bottom: 1.5rem;
    margin-top: 0; } }

.margin-top-0 {
  margin-top: 0 !important; }

$tablet: 768px;

@import "~bulma";
@import '~bulma-pricingtable';
//@import '~bulma-floating-button'

.navbar-burger:hover {
  background-color: unset; }

.logo {
  color: #098c8c;
  letter-spacing: 0.02em; }

.title-color {
  color: #098c8c;
  letter-spacing: 0.02em; }

.ohnohoney {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1; }

.has-shadow {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02); }

.is-aliceblue {
  background-color: aliceblue; }

.is-lightgrey {
  background-color: #fafaf7; }

.has-border-grey {
  border: 1px solid lightgrey; }

.table.is-narrow td, .table.is-narrow th {
  padding: 1em 1em; }

.is-backgroundless {
  background: none !important; }

.is-min-half-height {
  min-height: 50vh; }

.footer {
  padding: 3rem 1.5rem; }

footer a {
  color: #476282;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400; }

footer a:not(:last-child):after {
  content: "·";
  margin: 0 8px; }

.has-gradient-purple-bg {
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(204,212,242,1) 0%, rgba(255,255,255,1) 100%); }

.has-mustard-bg {
  background-color: #FDBB34; }

.paragraph {
  line-height: 1.8em; }

.is-white {
  background-color: #ffffff;
  background-image: none !important; }

.has-image-centered {
  margin-left: auto;
  margin-right: auto; }

.is-inline {
  display: inline !important; }

.is-tablecell {
  display: table-cell !important; }

.is-borderless {
  border: none !important; }

.is-256x256 {
  width: 256px;
  height: 256px; }

.is-margin-auto {
  margin: auto; }

.pr-point-one {
  padding-right: 0.15rem; }

.react-toast-notifications__toast {
  width: unset !important; }

.react-toast-notifications__container {
  top: 3.5rem !important; }

.is-transparent {
  background: transparent; }

main {
  max-width: 965px; }

.has-background-lightgrey {
  background: #fafafa; }

.has-border-bottom-lightgrey {
  border-bottom: 1px solid lightgrey !important;
  border-radius: unset; }

.has-border-top-lightgrey {
  border-top: 1px solid lightgrey !important;
  border-radius: unset; }

.breadcrumb a {
  padding: 0;
  margin: 0 0.75em; }

.breadcrumb li.is-active a {
  color: #098c8c;
  border-bottom: 1px solid #098c8c; }

.is-size-8 {
  font-size: 0.65em; }

.is-minwidth-300 {
  min-width: 300px; }

.ellipsis-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

ui5-responsive-popover {
  z-index: 2000 !important; }

.is-active-borderless {
  &:active {
    box-shadow: none !important; }
  &:focus {
    box-shadow: none !important; } }

ui5-dialog {
  ui5-icon {
    padding: unset !important;
    margin-right: 8px; } }

