@media only screen and (max-width: 600px) {
  table.queuetable,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    border-bottom: 1px solid #ccc;
  }
  tr:last-child {
    border-bottom: none;
  }
  td {
    border: none !important;
    position: relative;
    padding-left: 200px;
    margin-left: 150px;
  }

  td:before {
    position: absolute;
    top: 12px;
    left: 6px;
    width: 200px;
    padding-right: 40px;
    white-space: nowrap;
    margin-left: -150px;
  }
  td:nth-of-type(1):before {
    content: 'Status';
  }
  td:nth-of-type(2):before {
    content: 'Name';
  }
  td:nth-of-type(3):before {
    content: 'Expected';
  }
  td:nth-of-type(4):before {
    content: 'Action';
  }
}

td .is-narrow {
  padding: 0.8em 0.8em !important;
}

.queuetable thead td, .table thead th {
  border: none;
}

.queuetable td {
  vertical-align: middle !important;
  border: none !important;
}
